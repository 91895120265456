<template>
    <b-modal id="edit-stock"  ref="modal" size="md" class="w-100" title="Alimenter Stock" >

        <form ref="form">

            <b-form-group label="Quantite" >
                <b-form-input type="number" min="0" v-model="qte"></b-form-input>
            </b-form-group>

        </form>
        <template #modal-footer>
            <div class="w-100">
                <b-button variant="success" size="lg" class="float-right" @click="editItem">Ajouter</b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>

    export default {
        name: "EditStock",
        props:['id'],
        data(){
            return{
                qte:0
            }

        },
        methods: {
            editItem() {
                let qte= {
                    "qte":this.qte,
                };

                this.$http
                    .post('stocks/' + this.id, qte)
                    .then(response => {
                        this.$emit('refresh')
                        this.$refs['modal'].hide()
                        this.$alertify.success("L'opération a réussi :)")
                        this.qte = 0;
                        console.log(response)
                    })
                    .catch(error => {
                        console.log(error)
                        this.$alertify.error("Il y a une erreur, réessayez !");
                    })
            },

        },

    }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
