<template>
    <b-modal id="add-to-stock"  ref="modal" size="md" class="w-100" title="Ajouter Nouveau Article"
             @hide="bani" @shown="bani">

        <form ref="form">
            <div>
                <label >Nom d'Article</label>
                <div class="form-group form-group-label check-groupe">
                    <multiselect v-model="item"
                                 :options="products"
                                 track-by="name"
                                 label="name"
                                 :close-on-select="true"
                                 :multiple="false"
                                 :searchable="true"
                                 :clear-on-select="false"
                                 :show-no-results="false"
                                 :show-labels="false"
                                 noResult='Not Found'
                                 tag-placeholder="Ajouter"
                                 placeholder="Nom Article"
                    >
                    </multiselect>
                </div>

            </div>
            <div >

                <b-form-group v-for="(value, name,index) in products_attribute" :label="name" v-bind:key="(value,name)" >
                    <b-form-select  :options="value"  v-model="designation_selected[index]"  value-field="id_attribute"
                                    text-field="name"></b-form-select>
                </b-form-group>
            </div>


            <b-form-group label="Quantite" >
                <b-form-input type="number" v-model="qte"></b-form-input>
            </b-form-group>



        </form>
        <template #modal-footer>
            <div class="w-100">
                <b-button variant="success" size="lg" class="float-right" @click="addItem">Ajouter</b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
    import Multiselect from 'vue-multiselect'
    export default {
        name: "AddToStock",
        components: {Multiselect},

        data() {
            return {
                item:{},
                qte:1,
                id_product_attribute:0,
                designation_selected:[],
                products_attribute:[],
                products:[],
                types: [
                    { value: 0, text: 'Facture' },
                    { value: 1, text: 'Bon De Commande' },
                ],


            }
        },

        watch:{
            'item.id_product': function(){

                if(this.item.id_product )  this.getAttributes()
            },
            'designation_selected': function(){
                let is_complete = true;
                this.designation_selected.forEach(item => {if(typeof (item) == "undefined") is_complete = false })
                if (is_complete && this.designation_selected.length == Object.keys(this.products_attribute).length && this.designation_selected.length!=0) {
                    this.getPrice();
                }
                else {console.log("mzl")}
            }

        },



        methods: {
            bani(){
                this.item={}
                this.qte = 1
                this.designation_selected = []
                this.products_attribute =[]
                this.id_product_attribute = 0
                this.getProducts();

            },
            getProducts() {
                this.$http
                    .get('products')
                    .then(response => {
                        this.products = response.data;
                        console.log(response)

                    })
                    .catch(error => {
                        console.log(error)
                        this.$alertify.error("Il y a une erreur, réessayez !");
                    })

            },



            getPrice(){
                this.$http
                    .post('getPrices', {"product_id":this.item.id_product,"ids_attribute":this.designation_selected.join()})
                    .then(response => {
                        this.id_product_attribute = response.data.id_product_attribute;
                        console.log(response)

                    })
                    .catch(error => {
                        console.log(error)
                        this.$alertify.error("Cette Désignation n'est pas disponible :(");
                    })
            },
            getAttributes(){
                this.$http
                    .get('products/'+this.item.id_product+'/product_attribute')
                    .then(response => {
                        let products_attribute = response.data.attributes.reduce((r, a) => {
                            r[a.attribute_name] = [...r[a.attribute_name] || [], a];
                            return r;
                        }, {});
                        this.products_attribute = products_attribute;
                        this.designation_selected = this.designation_selected.splice(0,0);



                    })
                    .catch(error => {
                        console.log(error)
                        this.$alertify.error("Il y a une erreur, réessayez !");
                    })
            },


            addItem() {
                let stock = {
                    "nom_agence": this.$route.params.name,
                    "id_produit":this.item.id_product,
                    "si":this.qte,
                    "id_comb":this.id_product_attribute,
                };


                this.$http
                    .post('stocks', stock)
                    .then(response => {
                        this.$emit('refresh')
                        this.$alertify.success("L'opération a réussi :)")
                        this.bani()
                        console.log(response)

                    })
                    .catch(error => {
                        console.log(error)
                        if(error.response.data.message == "duplicate")this.$alertify.error("Element existant !")
                        else this.$alertify.error("Il y a une erreur, réessayez !");
                    })
            },
        },

    }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
