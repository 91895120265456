<template>
    <main>
        <div class="row  m-auto" style="width: 90%">
            <div class="col-xl-12 mt-5">
                <h1>Liste des articles disponibles - {{$route.params.name}}</h1>
            </div>
        </div>
        <div class="row  m-auto" style="width: 70%">
            <div class="col-xl-3  mt-2" >
                <div class="form-group mt-3 mb-0">

                    <b-form-input type="search" v-model="filter_all"
                                  placeholder="Recherche"></b-form-input>
                </div>
            </div>
            <div class="col-xl-3 " v-if="$is_admin">
                <div class="form-group mt-3 pt-2 mb-0 ">
                    <b-button class="w-100" @click="$bvModal.show('add-to-stock')" variant="info" size="sm" >
                        Ajouter
                    </b-button>
                    <add-to-stock  v-on:refresh="refresh_data"/>
                </div>
            </div>
        </div>
        <div class="row mt-5 ">
            <div class="col-xl-8 m-auto">
                <table class="text-center">
                    <thead>
                    <tr>
                        <th scope="col">Article</th>
                        <th scope="col">Attributs</th>
                        <th scope="col">SI</th>
                        <th scope="col">Vente</th>
                        <th scope="col">S.Réel</th>
                        <th scope="col"  v-if="$is_admin">Historiques</th>
                        <th scope="col" v-if="$is_admin">Actions</th>

                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in articles_filtred" :key='item.id' v-bind:style= "[item.reel<0 ? {'background-color':'#ff4d4d'} : {'background-color':'inherit'}]">
                        <td data-label="Article"> {{item.article.name}}</td>
                        <td data-label="Attributs" style="min-width: 300px !important;text-align: left">
                            <p class="margin-no " v-for="att in item.attributes" :key='att.id_attribute' >
                                <b>- {{att.attribute_name}} :</b> {{att.name}}<br>

                            </p>
                            <p class="margin-no " v-if="item.attribut_particular">- {{item.attribut_particular}}</p>
                        </td>
                        <td data-label="SI"> {{item.si}}</td>
                        <td data-label="Vente"> {{item.ventes}}</td>
                        <td data-label="S.Réel"> {{item.reel}}</td>
                        <td data-label="Historiques" class="pt-1 pb-1"  v-if="$is_admin">
                            <button class="btn-primary mr-2" style="border-radius: 4px" @click="$router.push({name:'Historiques',params:{id:item.id}})">Voir Historiques</button>
                        </td>
                        <td data-label="Actions" v-if="$is_admin">
                            <ul class="list-inline  mb-0 ml-1">
                                <li class="list-inline-item m-0 " @click="showEdit(item.id)"><i class="fas fa-edit"></i>
                                </li>
                                <li class="list-inline-item m-0 " @click="deleteItem(item.id)"><i
                                        class="fas fa-trash-alt" role="button"></i></li>
                            </ul>
                        </td>
                    </tr>

                    </tbody>
                </table>
            </div>
        </div>
        <edit-stock
                :id="selectedId"
                v-on:refresh="refresh_data"
        />
        <delete-confirmation :id="selectedId" type="stocks/" v-on:refresh="refresh_data"/>
    </main>
</template>

<script>
    import AddToStock from "@/components/AddToStock";
    import DeleteConfirmation from "@/components/deleteConfirmation";
    import EditStock from "@/components/EditStock";
    export default {
        name: "Stock",
        components: {EditStock, DeleteConfirmation, AddToStock},
        data() {
            return {
                articles: [],
                articles_filtred:[],
                selectedId: '',
                qte:0,
                filter_all:''


            }
        },
        watch: {
            filter_all: function () {
                this.articles_filtred = this.search()
            },
        },
        created() {
            this.getArticles();

        },
        methods: {
            search() {
                return this.articles.filter(item => {
                    let name = item.article.name.toString().toLowerCase();
                    let searchTerm = this.filter_all.toLowerCase();
                    return   name.includes(searchTerm)
                });
            },

            showEdit(id) {
                this.selectedId = id;
                this.$root.$emit('bv::show::modal', 'edit-stock')
            },
            deleteItem(id) {
                this.selectedId = id
                this.$root.$emit('bv::show::modal', 'delete')
            },
            getArticles() {
                this.$http
                    .get('stocks/' + this.$route.params.name)
                    .then(response => {
                        this.articles = response.data
                        this.articles.forEach(function (item) {
                            if(response.data.attribut_particular)item.attribut_particular = response.data.attribut_particular
                            item.pt = parseFloat(item.qte) * parseFloat(item.unit_price)
                        });
                        this.articles_filtred = this.search()

                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            refresh_data() {
                this.getArticles()
            },

        },

    }
</script>

<style scoped src="@/assets/table.css">

</style>